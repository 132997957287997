var centerMap = [40.456017, -3.699503];

var mymap = L.map('mapid', {zoomControl: false}).setView(centerMap, 5);

L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
maxZoom: 18,
minZoom: 3,
attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
id: 'mapbox/light-v9',
tileSize: 512,
zoomOffset: -1
}).addTo(mymap);

var mapMarker = L.icon({
    iconUrl: '../assets/img/icon/map-marker.png',
    iconSize: [60, 60],
    id: 'iconMarker'
});
var userLocation = L.icon({
    iconUrl: '../assets/img/icon/current-location.png',
    iconSize: [60, 60],
    id: 'iconMarker'
});

L.marker(centerMap, {icon: mapMarker}).on('click', function() {
    
    mymap.setView([40.456017, -3.699503], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Madrid</span>');
    
}).addTo(mymap);
L.marker([41.474491, 2.092832], {icon: mapMarker}).on('click', function() {
    
    mymap.setView([41.474491, 2.092832], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Barcelona</span>');
    
}).addTo(mymap);
L.marker([41.668049, -4.725738], {icon: mapMarker}).on('click', function() {
    
    mymap.setView([41.668049, -4.725738], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Valladolid</span>');
    
}).addTo(mymap);

//Controls map--------------------------------------------------------------------------
//topleft------------------------------------------------------------------------------------------
L.control.custom({
    position: 'topleft',
    content: '<div class="style_fwsc" style="width: 100%;">' +
    
    //compress ---------------------------------------------------
    '<div class="style_fwss" style="padding-right: 5px;">'+
    '<div class="d-flex" style="border: .5px solid rgba(255,82,82, .85);; border-radius: .9rem; width: 1.8rem!important; height: 1.8rem!important; flex-wrap: wrap; justify-content: center; align-content: center; background-color: rgba(255,255,255, .8);"><i  id="zoomCenter" class="fa fa-compress-arrows-alt fa-lg" style="color: #ff5252;"></i></div>' +
    '</div>' +
    //compress ---------------------------------------------------
    
    '</div>',//Principal
    classes: 'style_fwcc',
    id: 'topleft_map',
    style: {
        margin: '10px',
        cursor: 'pointer',
    },
    datas: {
        'foo': 'bar'
    },
    events: {
        click: function(data) {
            
            switch (data.target.id) {
                
                case 'zoomCenter':
                mymap.setView(centerMap, 5);
                $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions España</span>');
                break;
                
                default:
                break;
            }
        }
    }
}).addTo(mymap);


//bottomleft------------------------------------------------------------------------------------------
L.control.custom({
    position: 'bottomleft',
    content: '<div class="style_fwsc" style="width: 100%;">' +
    
    //compress ---------------------------------------------------
    '<div class="style_fwss" style="padding-right: 5px;">'+
    '<div class="d-flex" style="border: .5px solid rgba(255,82,82, .85); border-radius: .9rem; width: 1.8rem!important; height: 1.8rem!important; flex-wrap: wrap; justify-content: center; align-content: center; background-color: rgba(255,255,255, .8);"><i  id="user_location" class="fa fa-location-arrow fa-lg" style="color: #ff5252;"></i></div>' +
    '</div>' +
    //compress ---------------------------------------------------
    
    '</div>',//Principal
    classes: 'style_fwcc',
    id: 'topleft_map',
    style: {
        margin: '10px',
        cursor: 'pointer',
    },
    datas: {
        'foo': 'bar'
    },
    events: {
        click: function(data) {
            
            switch (data.target.id) {
                
                case 'user_location':
                var options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };
                
                function success(pos) {
                    
                    var crd = pos.coords;
                    
                    console.log('Your current position is:');
                    console.log('Latitude : ' + crd.latitude);
                    console.log('Longitude: ' + crd.longitude);
                    console.log('More or less ' + crd.accuracy + ' meters.');
                    
                    var radius = crd.accuracy / 2;
                    
                    L.marker([crd.latitude,crd.longitude], {icon: userLocation}).addTo(mymap);
                    
                    
                    L.circle([crd.latitude,crd.longitude], radius, {
                        color: '#ff5252',
                        fillColor: '#ff7373',
                        fillOpacity: 0.5
                    }).addTo(mymap);
                    
                };
                
                function error(err) {
                    console.warn('ERROR(' + err.code + '): ' + err.message);
                };
                
                navigator.geolocation.getCurrentPosition(success, error, options);
                default:
                break;
            }
        }
    }
}).addTo(mymap);


//topright-----------------------------------------------------------------------------------------------
L.control.custom({
    position: 'topright',
    content: '<div id="topRightMap" class="pull-left" style="font-size: .9rem; font-weight: 400; padding: 0.15rem .75rem; border-radius: .75rem; background-color: rgba(255,255,255,.85); border: 1px solid rgba(255,82,82,.5);"><span class="text-primary"><b>core</b>solutions España</span></div>',
    id: 'topright_map',
    style: {
        width: '100%',
        margin: '7px 3px',
        padding: '0 0 0 0',
        cursor: 'pointer',
        color: 'rgba(255,82,82,1)',
        
    }
}).addTo(mymap);

function changeServices(hide, show) {
    $('#service_'+hide).fadeOut(200, function() {
        $('#service_'+show).fadeIn(400);
    });
}

$('#addressMadrid').click(function() {
    
    mymap.setView([40.456017, -3.699503], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Madrid</span>');
    
});
$('#addressBarcelona').click(function() {
    
    mymap.setView([41.474491, 2.092832], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Barcelona</span>');
    
});
$('#addressValladolid').click(function() {
    
    mymap.setView([41.668049, -4.725738], 15);
    $('#topRightMap').html('<span class="text-primary"><b>core</b>solutions Valladolid</span>');
    
});